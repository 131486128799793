import {IAccordeonData} from "../../Accordion";

export const Data = [
    {
        question: 'What is a NFT',
        answer: 'By now you surely have already heard of it. If not: NFT stands for "Non-fungible token". That again means that it is a unique digital item. This unique item is what YOU can buy here and trad whenever you want.'
    },
    {
        question: 'What is a Wuschelkopf',
        answer: 'A digital art of a tousled character. The art images are generated algorithmically. Usually they are funny looking guys and girls, but there are rarer ghost and skeleton versions.'
    },
    {
        question: 'How do I get a Wuschelkopf?',
        answer: 'First, download and install the browser plugin called MetaMask. This will allow you to buy NFTs from Websites as this one.\n\n Secondly, you can either mint directly here a brand new Wuschelkopf or buy one from Opensea.io'
    },
    {
        question: 'What is MetaMask',
        answer: 'A crypto-wallet in which you can store your Ethereum. This Plug-In is necessary to buy a Wuschelkopf. By creating a wallet you will also own a Ethereum address, where your NFTs will be stored.\n Learn more about Metamask here:\n (https://metamask.io)'
    },

]