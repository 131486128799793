

export const myColors = {
    primary: '#671b8d',
    secondary: '#f66804',
    danger: '#a5f311',
    lime: '#89ba2e',
    dark_lime: '#5d7e1f',
    light_purple: '#be84db',
    ultra_light_purple: '#d0a6e5',
    light_orange: '#fe8d4f',
    light_lime: '#ccf381',
    black: '#000',
    almost_black: '#010606',
    dark_grey: '#181818',
    middle_grey: '#2d2d2d',
    light_grey: '#656464',
    very_light_grey: '#e8e7e7',
    light_grey_darker: '#525252',
    white: '#fff',
    old_white: '#faebd7',


    // rarities section colors --> additional color shades
    background_orange: '#fca575',
    highlight_orange: '#fd7000',
    highlight_purple: '#a003b9',
    background_green: '#d1eca1',



    // external
    twitter_blue: '#1DA1F2',
    discord_blue: '#7289da',
    openSea_blue: '#2081e2'
}

export const pageSizes = {
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1800px'
}

export const spacing = {
    half: '8px',
    default: '16px',
    double: '32px',
    quatriple: '64px'
}

export const fontSizes = {
    title_xl: '72px',
    title_lg: '60px',
    title_sm: '48px',
    title_s: '40px',
    title_xs: '36px',
    default_s: '18px',
    default_m: '24px',
    default_l: '36px'
}