// first line
import Image21 from '../../images/slider/adam_joint.png'
import Image22 from '../../images/slider/hero_male.png'
import Image23 from '../../images/slider/hair_Green_male.png'
import Image24 from '../../images/slider/disco.png'
import Image25 from '../../images/slider/domina.png'
import Image26 from '../../images/slider/elegant.png'
import Image27 from '../../images/slider/fairy.png'
import Image28 from '../../images/slider/ghost_rider.png'
import Image29 from '../../images/slider/pride_oger.png'
import Image221 from '../../images/slider/dead_eva.png'
import Image222 from '../../images/slider/hero_female.png'
import Image223 from '../../images/slider/burning_straw.png'

// second line
import Image31 from '../../images/slider/hipster.png'
import Image32 from '../../images/slider/jedi_ghost.png'
import Image33 from '../../images/slider/joker_skeleton.png'
import Image34 from '../../images/slider/mage_glasses.png'
import Image35 from '../../images/slider/odd_ghost.png'
import Image36 from '../../images/slider/z_einfachgeil.png'
import Image37 from '../../images/slider/oger_joker.png'
import Image38 from '../../images/slider/playboy.png'
import Image39 from '../../images/slider/glowing_chrsitmas.png'
import Image331 from '../../images/slider/puppet.png'
import Image332 from '../../images/slider/python.png'
import Image333 from '../../images/slider/skeleton_mage.png'


// third line
import Image41 from '../../images/slider/z_disco_drunk.png'
import Image42 from '../../images/slider/z_disco_ghost.png'
import Image43 from '../../images/slider/z_drunken_lepricon.png'
import Image44 from '../../images/slider/oger_baby.png'
import Image45 from '../../images/slider/z_illidan.png'
import Image46 from '../../images/slider/z_odd_jedi.png'
import Image47 from '../../images/slider/z_oger_mage.png'
import Image48 from '../../images/slider/z_skeleton_mage.png'
import Image49 from '../../images/slider/z_something.png'
import Image441 from '../../images/slider/z_styler.png'
import Image442 from '../../images/slider/z_swim_animals.png'
import Image443 from '../../images/slider/z_woman.png'


// Interface for images in the applied in the slider
export interface IImageInfo {
    image: any,
    alt: string
}





export const imageSources_one: IImageInfo[]= [
    {
        'image': Image21,
        'alt': '21'
    },
    {
        'image': Image22,
        'alt': '22'
    },
    {
        'image': Image23,
        'alt': '23'
    },
    {
        'image': Image24,
        'alt': '24'
    },
    {
        'image': Image25,
        'alt': '25'
    },
    {
        'image': Image26,
        'alt': '26'
    },
    {
        'image': Image27,
        'alt': '27'
    },
    {
        'image': Image28,
        'alt': '28'
    },
    {
        'image': Image29,
        'alt': '29'
    },
    {
        'image': Image221,
        'alt': '221'
    },
    {
        'image': Image222,
        'alt': '222'
    },
    {
        'image': Image223,
        'alt': '223'
    },
]

export const imageSources_two: IImageInfo[]= [
    {
        'image': Image31,
        'alt': '31'
    },
    {
        'image': Image32,
        'alt': '32'
    },
    {
        'image': Image33,
        'alt': '33'
    },
    {
        'image': Image34,
        'alt': '34'
    },
    {
        'image': Image35,
        'alt': '35'
    },
    {
        'image': Image36,
        'alt': '36'
    },
    {
        'image': Image37,
        'alt': '37'
    },
    {
        'image': Image38,
        'alt': '38'
    },
    {
        'image': Image39,
        'alt': '39'
    },
    {
        'image': Image331,
        'alt': '331'
    },
    {
        'image': Image332,
        'alt': '332'
    },
    {
        'image': Image333,
        'alt': '333'
    },
]

export const imageSources_three: IImageInfo[]= [
    {
        'image': Image441,
        'alt': '441'
    },
    {
        'image': Image41,
        'alt': '41'
    },
    {
        'image': Image42,
        'alt': '42'
    },
    {
        'image': Image43,
        'alt': '43'
    },
    {
        'image': Image44,
        'alt': '44'
    },
    {
        'image': Image45,
        'alt': '45'
    },
    {
        'image': Image46,
        'alt': '46'
    },
    {
        'image': Image47,
        'alt': '47'
    },
    {
        'image': Image48,
        'alt': '48'
    },
    {
        'image': Image49,
        'alt': '49'
    },
    {
        'image': Image442,
        'alt': '442'
    },
    {
        'image': Image443,
        'alt': '443'
    },

]